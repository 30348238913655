import _core2 from "@styled-system/core";
var exports = {};
exports.__esModule = true;
exports["default"] = exports.position = void 0;
var _core = _core2;
var defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512]
};
var config = {
  position: true,
  zIndex: {
    property: "zIndex",
    scale: "zIndices"
  },
  top: {
    property: "top",
    scale: "space",
    defaultScale: defaults.space
  },
  right: {
    property: "right",
    scale: "space",
    defaultScale: defaults.space
  },
  bottom: {
    property: "bottom",
    scale: "space",
    defaultScale: defaults.space
  },
  left: {
    property: "left",
    scale: "space",
    defaultScale: defaults.space
  }
};
var position = (0, _core.system)(config);
exports.position = position;
var _default = position;
exports["default"] = _default;
export default exports;
export const __esModule = exports.__esModule;
const _position = exports.position;
export { _position as position };